export const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

// Should be in sync with dev center page id
export const GROUPS_MEMBERS_AREA_PAGE_ID = 'groups_area';

export const GROUPS_PLATFORM_CONTROLLER_TYPE = 'groupsPlatform';

export const GROUP_DISCUSSION_ID = 'discussion';

// page Id from https://dev.wix.com/
export const GROUPS_PAGE_ID = 'groups';
export const GROUP_PAGE_ID = 'group';

export const ABOUT_GROUP_PAGE_ID = 'about_group';
export const GROUP_PAGES = [GROUP_PAGE_ID, GROUPS_PAGE_ID];

export const PAGE_TITLES = {
  [GROUP_PAGE_ID]: 'Group Page',
  [GROUPS_MEMBERS_AREA_PAGE_ID]: 'Groups',
  [GROUPS_PAGE_ID]: 'Groups List',
};

export const EXPERIMENTS_SCOPE = 'specs.groups';
export const GROUPS_DASHBOARD_URL = 'social-groups-dashboard';
