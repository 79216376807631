import { EditorReadyFn, OnEventFn } from '@wix/yoshi-flow-editor';

import GroupPlatform from './GroupPlatform';
import { AppActionEvent, PageActionsEvent } from './types/manifest';
import { HandleAction } from './types/editor-app';

let platform: GroupPlatform;

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  options,
  flowAPI,
) => {
  console.info('yoshi editor flow migration');
  const { firstInstall, initialAppData } = options;
  platform = new GroupPlatform(
    editorSDK,
    appDefinitionId,
    flowAPI,
    initialAppData,
  );
  try {
    if (firstInstall) {
      await platform.install();
      await platform.changeLandingPage();
    } else {
      await platform.setGroupPageState(appDefinitionId);
      // For sites where groups members area was installed
      // TODO: 🚨 remove later see https://jira.wixpress.com/browse/GROUP-673
      await platform.deleteGroupsMemberArea();
    }
  } catch (e) {
    console.error('Groups App installation: FAIL');
    await platform.deleteApp();
    throw e;
  }
};
export const onEvent: OnEventFn = (event) => {
  try {
    switch (event.eventType as any) {
      case AppActionEvent.ADD_PAGE:
        return platform.addPage();
      case PageActionsEvent.REMOVE:
        return platform.deleteApp();
      case AppActionEvent.APP_ACTION_CLICK:
        return platform.performAction(event.eventPayload.actionId);
      default:
        return;
    }
  } catch (e) {
    console.log('[onEvent] Error');
  }
};

export const getAppManifest = async () => {
  return platform.getManifest();
};

export const handleAction: HandleAction = async ({ type, payload }) => {
  console.log('>: handleAction call', type, payload);
  if (!platform) {
    // very strange case when editor ready is called after `handleAction`
    return;
  }
  if (type === 'appInstalled') {
    try {
      switch (payload.appDefinitionId) {
        case platform.appDefId:
          await platform.setGroupPageState(platform.appDefId);
          console.log('GROUPS_PLATFORM installed');
          break;
        default:
      }
    } catch (e) {
      console.error('Groups App handle action: FAIL');
      await platform.deleteApp();
      throw e;
    }
  }
};
