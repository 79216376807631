import { Experiments } from '@wix/yoshi-flow-editor';

export async function getExperimentsByScope(
  scopes: string[],
  entityId: string,
  baseUrl?: string,
) {
  return new Experiments({
    scopes,
    baseUrl,
    requestContext: { overrideCriteria: { entityId } },
    useNewApi: true,
  });
}

export async function conductAll(
  scopes: string[],
  metaSiteId: string,
  baseUrl?: string,
) {
  const experiments = await getExperimentsByScope(scopes, metaSiteId, baseUrl);
  await experiments.ready();
  return experiments.all();
}
